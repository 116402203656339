@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

.glide__slide img{
  width:100%;
  display:block;
}
.glide__arrow {
  position: absolute;
  top: 50%;
  background: transparent;
  border: 0;
  font-size: 2rem;
  color: #FFF;
  cursor: pointer;
}
.glide__arrow.glide__arrow--left{
  left:1rem;
}
.glide__arrow.glide__arrow--right{
  right: 1rem;
}
